import StatesLib from '@filingramp/ramp-lib/lib/states';

export const renderStateName = (state) => StatesLib.getStateDisplay(state);

export const STATE_LIST = StatesLib
  .getUsaStates()
  .map(value => ({
    label: renderStateName(value),
    value,
  })).sort((a, b) => a.label.localeCompare(b.label))

export const PROVINCE_LIST = StatesLib
  .getCanadaStates()
  .map(value => ({
    label: renderStateName(value),
    value,
  }));
