import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import RequireFilingDraftsFeature from './helpers/RequireFilingDraftsFeature';
import RequireWorkflowFeature from './helpers/RequireWorkflowFeature';
import RequireRampDriveFeature from './helpers/RequireRampDriveFeature';
import RequireAnalyticsFeature from './helpers/RequireAnalyticsFeature';
import RequireCircularsFeature from './helpers/RequireCircularsFeature';
import Home from './pages/Home';
import Redirect from './pages/Redirect';
import ObjectionLetterMiddleware from './helpers/ObjectionLetterMiddleware';
// import Chakra from './pages/Chakra';
const Auth = lazy(() => import( './helpers/Auth'));
const LibraryFormsV3 = lazy(() => import( './pages/LibraryV3/FormsLibrary'));
const LibraryFormV3 = lazy(() => import( './pages/LibraryV3/FormsLibrary/FormStatus'));
const LibraryRatesV3 = lazy(() => import( './pages/LibraryV3/RatesLibrary'));
const LibraryRateV3 = lazy(() => import( './pages/LibraryV3/RatesLibrary/RateStatus'));
const Login = lazy(() => import( './pages/Login'));
const PasswordReset = lazy(() => import( './pages/PasswordReset/PasswordReset'));
const ForgotPassword = lazy(() => import( './pages/Login/ForgotPassword/ForgotPassword'));
const AzureAuth = lazy(() => import( './pages/AzureAuth'));
const SamlAuth = lazy(() => import( './pages/SamlAuth'));
const Dashboard = lazy(() => import( './pages/Dashboard'));
const Profile = lazy(() => import( './pages/Profile'));
const Project = lazy(() => import( './pages/ProjectDetails'));
const StateDetails = lazy(() => import( './pages/StateDetails'));
const Objections = lazy(() => import( './pages/Objections'));
const ObjectionLetter = lazy(() => import( './pages/ObjectionLetter'));
const Admin = lazy(() => import( './pages/Admin/Admin'));
const Search = lazy(() => import( './pages/Search'));
const FilingsDrafts = lazy(() => import( './pages/FilingDrafts'));
const FilingDraft = lazy(() => import( './pages/FilingDraft'));
const Filing = lazy(() => import( './pages/Filing'));
const Products = lazy(() => import( './pages/Products'));
const Product = lazy(() => import( './pages/Product'));
const FilingReporting = lazy(() => import( './pages/Reporting'));
// const FilingReportingV2 = lazy(() => import( './pages/v2/FilingReporting'));
const Circulars = lazy(() => import( './pages/Circulars'));
const ProjectReporting = lazy(() => import( './pages/ProjectReporting'));
const Organizer = lazy(() => import( './pages/Organizer'));
const HelpCenter = lazy(() => import( './pages/HelpCenter/HelpCenter'));
const FilingAmendment = lazy(() => import( './pages/Filing/FilingAmendment'));
const FilingPostSubmissionUpdates = lazy(() => import( './pages/Filing/FilingPostSubmissionUpdates'));
const FilingSetup = lazy(() => import( './pages/FilingSetup'));
const Documentation = lazy(() => import( './pages/Documentation'));
const ReleaseNotes = lazy(() => import( './pages/ReleaseNotes'));
const Drive = lazy(() => import( './pages/Drive/DriveWrapper'));
const Analytics = lazy(() => import( './pages/Analytics'));
const NoAccess = lazy(() => import( './pages/NoAccess'));

const Scopes = require('./helpers/scopes');

const RoutesList = ({ isAuthChecked }) => (
  <Routes>
    {/* Public Routes */}
    <Route exact path="/" element={<Home isAuthChecked={isAuthChecked} />} />
    <Route exact path="/login" element={<Login />} />
    {/*<Route exact path="/chakra" element={<Chakra />} />*/}
    <Route
      exact
      path="/user/forgot-password"
      element={<PasswordReset />}
    />
    <Route exact path="/forgot-password" element={<ForgotPassword />} />
    <Route exact path="/azure/auth" element={<AzureAuth />} />
    <Route exact path="/saml/auth" element={<SamlAuth />} />
    {/* End Public Routes */}

    {/* Authenticated Routes */}
    <Route
      exact
      path="/dashboard"
      element={<Auth feature="DASHBOARD" Component={Dashboard} />}
    />
    <Route
      exact
      path="/products"
      element={<Auth feature="FILINGS" Component={Products} />}
    />
    <Route
      exact
      path="/products/:productId"
      element={<Auth Component={Product} />}
    />
    <Route
      exact
      path="/profile"
      element={<Auth Component={Profile} />}
    />
    <Route
      exact
      path="/project/:projectId"
      element={<Auth Component={Project} />}
    />
    <Route
      exact
      path="/project/:projectId/:stateCode"
      element={<Auth feature="FILINGS_STATE" Component={StateDetails} />}
    />
    <Route
      exact
      path="/forms-library"
      element={<Auth feature="LIBRARY" Component={LibraryFormsV3} />}
    />
    <Route
      exact
      path="/forms-library/:formId"
      element={<Auth feature="LIBRARY" Component={LibraryFormV3} />}
    />
    <Route
      exact
      path="/rates-library"
      element={<Auth feature="LIBRARY" Component={LibraryRatesV3} />}
    />
    <Route
      exact
      path="/rates-library/:rateId"
      element={<Auth feature="LIBRARY" Component={LibraryRateV3} />}
    />
    <Route
      exact
      path="/filing-drafts/new"
      element={<Auth feature="FILING_DRAFTS" Component={RequireFilingDraftsFeature(FilingSetup)} />}
    />
    <Route
      exact
      path="/filing-drafts"
      element={<Auth feature="FILING_DRAFTS" Component={RequireFilingDraftsFeature(FilingsDrafts)} />}
    />
    <Route
      path="/filing-draft/:filingDraftId"
      element={<Auth feature="FILING_DRAFTS" Component={RequireFilingDraftsFeature(FilingDraft)} />}
    />
    <Route
      exact
      path="/filing/:filingId"
      element={<Auth feature="FILINGS_FILING" Component={Filing} />}
    />
    <Route
      exact
      path="/filing/:filingId/amendment-draft"
      element={<Auth Component={FilingAmendment} />}
    />
    <Route
      exact
      path="/filing/:filingId/post-submission-draft"
      element={<Auth Component={FilingPostSubmissionUpdates} />}
    />
    <Route
      exact
      path="/objections"
      element={<Auth feature="OBJECTIONS" Component={RequireWorkflowFeature(Objections)} />}
    />
    <Route
      exact
      path="/reporting/filings"
      element={<Auth feature="REPORTING" Component={FilingReporting} />}
    />
    {/*<Route*/}
    {/*  exact*/}
    {/*  path="/v2/reporting/filings"*/}
    {/*  element={<Auth feature="REPORTING" Component={FilingReportingV2} />}*/}
    {/*/>*/}
    <Route
      exact
      path="/reporting/projects"
      element={<Auth Component={ProjectReporting} />}
    />
    <Route
      exact
      path="/circulars"
      element={<Auth Component={RequireCircularsFeature(Circulars)} />}
    />
    <Route
      exact
      path="/organizer"
      element={<Auth feature="ORGANIZER" Component={Organizer} />}
    />
    <Route
      exact
      path='/analytics'
      element={<Auth feature="ANALYTICS" Component={RequireAnalyticsFeature(Analytics)} />}
    />
    <Route
      exact
      path="/objections/dashboard/:filingId/:objectionId"
      element={<Auth feature="OBJECTIONS" Component={ObjectionLetterMiddleware(RequireWorkflowFeature(ObjectionLetter))} />}
    />
    <Route
      exact
      path="/help-center"
      element={<Auth Component={HelpCenter} />}
    />
    <Route
      exact
      path="/admin"
      element={<Auth Component={Admin} scopes={[Scopes.Admin, Scopes.AccountAdmin]} />}
    />
    <Route
      exact
      path="/documentation"
      element={<Auth Component={Documentation} />}
    />
    <Route
      exact
      path="/drive/:driveId"
      element={<Auth feature="DRIVE" Component={RequireRampDriveFeature(Drive)} />}
    />
    <Route
      exact
      path="/drive"
      element={<Auth Component={RequireRampDriveFeature(Drive)} />}
    />
    <Route
      exact
      path="/release-notes"
      element={<Auth Component={ReleaseNotes} />}
    />
    <Route
      exact
      path="/no-access"
      element={<Auth Component={NoAccess} />}
    />
    <Route path="/search/:query?" element={<Auth feature="SEARCH" Component={Search} />} />
    <Route path="/*" element={<Redirect />} />
  </Routes>
);
export default RoutesList;
